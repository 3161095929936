
const tableOption = {
    menu: true, // 是否显示操作拦
    height: 300,
    border: true,
    reserveSelection: true, // 翻页多选
    card: false,
    searchMenuSpan: 6, //搜索按钮栅格
    labelWidth: 120, //表单项label宽度
    // searchIndex: 3, // 搜索收缩个数
    // searchIcon: true, //  是否搜索收缩
    refreshBtn: false, //刷新
    columnBtn: false, // 动态列隐藏
    searchShowBtn: false, // 搜索框隐藏按钮
    align: 'center', //表格对齐方式
    tip: false, // 多选统计
    // selection: true, // 是否开启多选
    delBtn: false, // 操作栏的删除
    addBtn: false,
    editBtnText: '更新',
    // editBtn: false, // 操作栏的编辑
    column: [
        {
            label: '版本号',
            prop: 'version',
            rules: [{
                required: true,
                message: "请输入版本号",
                trigger: ['blur', 'change']
            }],
        },
        {
            label: '更新内容',
            prop: 'updateMsg',
            rules: [{
                required: true,
                message: "请输入更新内容",
                trigger: ['blur', 'change']
            }],
        },
        {
            label: '更新文件',
            prop: 'installUrl',
            rules: [{
                required: true,
                message: "请上传更新文件",
                trigger: ['blur', 'change']
            }],
        },
        {
            label: '安装包名称',
            prop: 'installName',
            editDisplay: false, // 编辑是否不可见
            addDisplay: false, // 新增是否不可见
        },

        {
            label: '更新时间',
            prop: 'updateTime',
            editDisplay: false, // 编辑是否不可见
            addDisplay: false, // 新增是否不可见

        },
    ]


}


export default tableOption
