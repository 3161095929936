<template>
  <div class="settingManagement">
    <Jump :jumpList="jumpList" />
    <NavMenu :activeUrl="activeUrl" />
    <div class="page-wrap">
      <chil-menu />
      <div class="page-main">
        <div class="log-container">
          <avue-crud
                  v-model="form"
                  :data="data"
                  :option="option"
                  :table-loading="showLoading"
                  @row-update="rowUpdate"
                  ref="crud"
          >
            <template slot="installUrlForm">
              <fileUpload
                      @submitImg="imgInput"
                      :accept="'.apk'"
                      :limit="1"
                      :limitSize="100"
              ></fileUpload>
            </template>
          </avue-crud>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavMenu from "../navMenu.vue";
import Jump from "@/components/jump";
import option from "./tableOption";
import { queryVersion ,versionUpdate} from "@/api/setting";
import fileUpload from "@/components/fileUpload/index.vue";
import ChilMenu from "../chilMenu.vue";
export default {
  name: "settingManagement",
  components: {
    ChilMenu,
    Jump,
    NavMenu,
    fileUpload,
  },
  data() {
    return {
      form: {},
      data: [],
      option: option,
      showLoading: false,
      activeUrl: "/setting/system",
      jumpList: [
        { name: "首页",},
        { name: "系统管理",},
        { name: "app版本管理", },
      ],
      isActiveTabs: 0,
    };
  },
  mounted() {
    this.getVersion();
  },
  methods: {
    imgInput(e, name) {
      console.log("imgInput", e, name);
      this.url = e[0].response;
      this.form.installUrl = e[0].response;
      this.form.installName = e[0].name;
    },
    async getVersion() {
      this.showLoading = true;
      const res = await queryVersion();
      this.data = [];
      this.data.push(res);
      this.showLoading = false;
      console.log(res);
    },

    async rowUpdate(form, index, done, loading) {
      form.installUrl = this.url;
      const res = await versionUpdate(form);
      this.$message.success("更新成功");
      this.getVersion();
      done();
    },
  },
};
</script>
<style lang="scss">
.settingManagement{
  padding: 0 10px;
}
.page-main{
  display: flex;
  flex: 1;
}
.page-wrap {
  display: flex;
  .log-container {
    background: transparent;
    box-sizing: border-box;
    padding: 0 20px;
    flex: 1;
    width: 0;
  }
}
.log-container {
  margin-top: 20px;
  .user-wrap {
    display: flex;
    .user-tree {
      width: 230px;
      flex-shrink: 0;
      padding-top: 20px;
    }
  }
  .user {
    height: 100%;

    &__tree {
      padding-top: 3px;
      padding-right: 20px;
    }

    &__main {
      .el-card__body {
        padding-top: 0;
      }
    }
  }
  .el-tree {
    padding-top: 15px;
    height: calc(100vh - 300px);
    background: #0c265a;
  }
  .el-card {
    background-color: transparent;
    border: none;
  }
  .avue-crud {
    background: transparent;
  }
  .avue-crud__menu {
    min-height: 0px;
    background: transparent;
  }
  ::v-deep .single-row {
    font-family: "MicrosoftYaHei";
  }
  ::v-deep .double-row {
    background: #0c265a !important;
    color: #ffffff;
    font-size: 14px;
    font-family: "MicrosoftYaHei";
    font-weight: 400;
  }
  .avue-crud .el-table th {
    background: rgb(17, 50, 117);
    color: rgb(163, 214, 255);
    border: none;
  }
  .el-table tr {
    color: #ffffff;
    background-color: #081e49;
  }
  .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background-color: #0c265a;
    border-bottom: 1px solid #1d3b6a;
    border-top: 1px solid #1d3b6a;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px;
  }
  .el-table th.el-table__cell {
    color: #a3d6ff;
    background-color: #113275;
  }
  .el-table td.el-table__cell div {
    white-space: nowrap;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before,
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    background-color: #1d3b6a;
  }
  .el-table--border,
  .el-table--group {
    border: none;
    margin-top: 20px;
  }
  .el-table__body tr.hover-row.current-row > td.el-table__cell,
  .el-table__body
    tr.hover-row.el-table__row--striped.current-row
    > td.el-table__cell,
  .el-table__body tr.hover-row.el-table__row--striped > td.el-table__cell,
  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #113275 !important;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
  }
  .el-pagination__total {
    color: #a3d6ff;
  }
  .el-tag {
    border-color: #1d3b6a;
    background-color: transparent;
  }
  .avue-crud {
    width: 95%;
  }

  // button {
  //   height: 36px;
  //   background: linear-gradient(0deg, #1f79ff, #23b4ff);
  //   border-radius: 2px;
  //   line-height: 10px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  .el-button--small.is-circle {
    display: none;
  }
  // .el-card__body {
  //   padding-top: 20px !important;
  // }
  .el-scrollbar__wrap {
    overflow: auto;
  }
  .el-radio.is-bordered {
    border: 1px solid rgba(73, 108, 180, 0.36) !important;
  }
  .el-radio {
    color: #a3d6ff;
  }

  // .el-select-dropdown__list{
  //   background: #06193C;
  // }
  .el-pagination__jump {
    color: #a3d6ff;
  }
  .el-table__empty-block {
    background: #0c265a;
  }
  .avue-empty__desc {
    color: #a3d6ff;
  }
  .avue-empty__image img {
    display: none;
  }
  .avue-empty__image {
    height: 0px !important;
  }
}
.el-select-dropdown__list {
  background: #06193c;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: #0c265a;
}
.avue-dialog .el-tag.el-tag--info {
  color: #a3d6ff;
  background: #0c265a;
  border: 1px solid rgba(73, 108, 180, 0.36) !important;
}
.avue-dialog .el-dialog__header {
  background: url("../../../assets/dialog_header_bk.png");
  border-bottom: none !important;
  .el-dialog__headerbtn {
    top: 20px !important;
  }
}
.avue-dialog .el-dialog__title {
  color: #a3d6ff !important;
  // padding-left: 45px;
  font-size: 18px;
  font-weight: bold !important;
}
.avue-dialog .el-dialog__body {
  background: #0c265a;
}
.avue-dialog .avue-dialog__footer {
  background: #0c265a;
  border: none !important;
}
.el-collapse-item__wrap {
  background: transparent;
}
.avue-group .el-collapse,
.avue-group .el-collapse-item__wrap {
  border-color: transparent;
}
.avue-form__group--flex {
  .el-form-item__label {
    color: #a3d6ff;
  }
  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    // display: flex;
  }
}
.search {
  display: flex;
  margin-top: 15px;
  .el-input {
    width: 200px;
    margin-right: 10px;
  }
  .el-range-editor.el-input__inner {
    margin-right: 10px;
    .el-range-input {
      background-color: #06193c;
      color: rgba(163, 214, 255, 0.36) !important;
    }
  }
  .el-date-editor .el-range-separator {
    color: rgba(163, 214, 255, 0.76);
  }
  .el-date-editor .el-range__icon {
    color: rgba(163, 214, 255, 0.76);
  }
}
.el-date-editor {
  .el-range-input {
    color: rgba(163, 214, 255, 0.36);
    &::-webkit-input-placeholder {
      color: rgba(163, 214, 255, 0.36);
    }
  }
}
</style>
