<template>
  <div>
    <el-upload
      :action="action"
      :accept="accept"
      :on-success="handleSuccess"
      :file-list="fileLists"
      :before-upload="beforeAvatarUploadPdf"
      :on-remove="handleRemove"
    >
      <el-button class="import-btn" >
        <icon name="upload" scale="0.8" color="#3B98FF" fill="#fff"></icon>本地上传
      </el-button>
      <div slot="tip" class="el-upload__tip">
        支持格式：{{ accept }}
      </div>
    </el-upload>
  </div>
</template>

<script>
// import { getPathVideo } from "@/api/video";
export default {
  props: {
    action: {
      type: String,
      default: process.env.VUE_APP_BASE_API + "admin/sys-file/uploadFile",
    },
    accept: {
      type: String,
      default: ".mp4,.AVI,.mov,.rmvb,单个文件不能超过500MB",
    },
  },
  data() {
    return {
      fileLists: [],
      pathLists: [],
      videoLists: [],
      changeLists: 0, //选择视频选中的值
      visible: false,
      isVis: false
    };
  },
  methods: {
    beforeAvatarUploadPdf(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      let array = this.accept.split(",");
      array = array.map((item) => item.split(".")[1]);
      const extension0 = testmsg == array[0];
      const extension1 = testmsg == array[1];
      const extension2 = testmsg == array[2];
      const extension3 = testmsg == array[3];
      if (!extension0 && !extension1 && !extension2 && !extension3) {
        this.$message.warning(`上传文件只能是 ${this.accept}格式!`);
        return false;
      }
      return extension0 || extension1 || extension2 || extension3;
    },
    // 移除文件之前
    handleRemove(file, fileList) {
      this.$emit("submitImg", fileList);
      this.fileLists = [];
      this.changeLists = []
    },
    handleSuccess(response, file) {
      console.log("1",file)
      this.fileLists = [file];
      this.$emit("submitImg", this.fileLists);
    },
    clear() {
      this.fileLists = [];
    },
    // isAction() {
    //   // this.isVis = true
    //   this.visible = true;
    //     if (this.videoLists.length == 0) {
    //       //请求选择视频的数据
    //       getPathVideo().then((data) => {
    //         this.pathLists = data.data;
    //         this.pathLists.forEach((item) => {
    //           this.videoLists.push(item.split("/")[item.split("/").length - 1]);
    //         });
    //         this.videoLists.forEach((item,index) => {
    //           this.videoLists[index] = {
    //             videoName:item,
    //             vIndex:index
    //           }
    //         })
    //       });
    //     } else {
    //       this.videoLists = [];
    //       getPathVideo().then((data) => {
    //         this.pathLists = data.data;
    //         this.pathLists.forEach((item) => {
    //           this.videoLists.push(item.split("/")[item.split("/").length - 1]);
    //         });
    //         this.videoLists.forEach((item,index) => {
    //           this.videoLists[index] = {
    //             videoName:item,
    //             vIndex:index
    //           }
    //         })
    //       });
    //   }
    // },
    handleSelectionPersonChange(val) {
      this.changeLists = this.pathLists[val[0].vIndex]
      if (val.length > 1) {
        this.$refs.personTable.clearSelection();
        this.$refs.personTable.toggleRowSelection(val.pop());
      }
      // if(val.length !== 0){
      //   this.changeLists = this.pathLists[val[0].vIndex]
      // }else {
      //   this.changeLists = 0
      // }
    },
    handleClose() {
      this.changeLists = 0
      this.visible = false
    }
  },
};
</script>

<style lang="scss" scoped>
.el-upload {
  .import-btn {
    color: #ffffff;
    background: rgba(0, 49, 106, 0);
    border: 1px solid #1d3b6a;
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
}
.el-upload__tip {
  color: #fff;
  line-height: normal;
}
.change {
  width: 100px;
  cursor: pointer;
  color: #ffffff;
  background: rgba(0, 49, 106, 0);
  border: 1px solid #1d3b6a;
  line-height: 35px;
  margin-top: 10px;
  // display: inline-block;
  position: absolute;
  top: -8.5px;
  left: 120px;
  svg {
    margin-right: 5px;
  }
}
.change-video {
  display: inline-block;
  margin-right: 10px;
}
.circular {
  height: 10px;
  width: 10px;
}
.info-wrap {
  height: 525px;
  padding-top: 15px;
}
</style>

<style >
.dia-button {
  display: flex;
  /* flex-direction: column-reverse; */
}
.el-dialog__header button {
  background: none;
}
.el-form-item__content .cancel-button {
  background: rgba(0, 49, 106, 0);
  margin-left: 320px;
}
.info-wrap .el-table--enable-row-transition {
  height: 526px !important;
}
</style>